import React from 'react'

import styles from './margin-container.module.scss'

const MarginContainer = () => {
    return(
        <div className={styles.marginContainer}>
        </div>
    )
}

export default MarginContainer